import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { HeadingSecondary } from '../Styles/headers'
import { theme } from '../Styles/theme'
import { Section } from "../Styles/section"
import OptinFreeJsCourseGif from './../images/optin-freejscourse.gif'

const gifWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const optinGif = css`
  width: 70%;
  border: 2px solid #f4f4f4;
`

export default () => {
  return (
    <div>
      <HeadingSecondary
        theme={theme}
        style={{ margin: "5rem 0 3rem", textAlign: "center", fontFamily: "Libre Baskerville" }}
      >
        Great! Just one more thing!
      </HeadingSecondary>
      <p style={{ fontFamily: "Libre Baskerville", textAlign: "center" }}>
        Check your email and confirm that you're not a robot by click the link and it will automatically redirect you to the free course access page. <br /> <br />
        If you don't see your email in your primary box, make sure to double
        check your spam or promotions tab.
      </p>
      <div css={gifWrapper}>
        <img
          css={optinGif}
          src={OptinFreeJsCourseGif}
          alt="optin confirmation"
        ></img>
      </div>
    </div>
  )
}